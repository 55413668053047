export interface SAMLTokenResponsePayload {
  id_token: string;
  issued: string;
  access_token: string;
  refresh_token: string;
  expires_in: string;
  token_type: string;
}

export interface SAMLRefreshTokenResponsePayload {
  id_token: string;
  access_token: string;
  expires_in: string;
  token_type: string;
}


export interface SAMLTokenRequestPayload {
  code: string;
  redirectURL: string;
}

export interface SAMLTokenExpiredPayload {
  redirectURL: string;
}
