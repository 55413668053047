import {IPNSUserRolePrivilege} from '@portal-ng/PNSModels/PNSUserRolePrivilege.model';
import {IPNSLocation} from '@portal-ng/PNSModels/PNSLocation.model';

export interface IPNSNotificationMechanism {
  id: number;
  updatedBy: string;
  userId: number;
  bundleId: string;
  deviceToken: string;
  targetARN: string;
}


export interface IPNSUserRoles {
  id: string;
  updatedBy: string;
  name: string;
  privileges: IPNSUserRolePrivilege[];
}

export interface IPNSUser {
  id: string;
  updatedBy: string;
  username: string;
  enabled: boolean;
  locked: boolean;
  forcePasswordReset: boolean;
  failedAttempts: string;
  signInCount: string;
  signedInAt: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  authenticationToken: string;
  roles: IPNSUserRoles[];
  locations: IPNSLocation[];
  roleNames: string[];
  privilegeNames: string[];
  locationIds: string[];
  custodialOrganizationId: string;
  notificationMechanisms: IPNSNotificationMechanism[];
  cognitoUUID: string;
  name: string;
}
