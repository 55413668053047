import {IPNSScheduledAppointment} from '@portal-ng/PNSModels/';

export interface IPNSProblemVisitReport {
  mrn: string;
  visitNumber: string;
  name: string;
  admitDate: string;
  unit: string;
  room: string;
  bed: string;
}

export interface IPNSProblemReport {
  reporter: string;
  facility: string;
  facilityID: string;
  organization: string;
  type: string;
  visits: IPNSProblemVisitReport[];
  description: string;
  environment: string;
  appointmentDetails?: IPNSScheduledAppointment;
}
