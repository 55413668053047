  export interface IPNSTaskManagement {
    taskType: string;
    updatedBy: string;
    visitId: number;
    id: number;
    taskStatus: string;
  }

  export interface IPNSTaskManagementSortMap {
    taskManagement_HOME_HEALTH_REFERRAL_null?: number;
    taskManagement_PHYSICAL_THERAPY_REFERRAL_null?: number;
    taskManagement_A2H_null?: number;
    taskManagement_NUTRITION_null?: number;
    taskManagement_PRESCRIPTION_null?: number;
    taskManagement_ScheduleFollowup_null?: number;
    taskManagement_SocialDeterminate_null?: number;
    taskManagement_SCHEDULE_TRANSPORT_null?: number;
  }

  export interface IValueCode {
    universalCode: boolean;
    updatedBy: string;
    sortOrder?: number;
    active: boolean;
    id: number;
    abbreviation: string;
    title: string;
    category: string;
  }

  export interface IPNSVisitAttribute {
    attributeId: number;
    updatedBy: string;
    visitId: number;
    enteredAt: Date;
    attributeName: string;
    valueCode: IValueCode;
    attributeModel: string;
    id: number;
    type: string;
    enteredBy: string;
    value: string;
  }

  export interface IManualScheduledAddress {
    zip: string;
    updatedBy: string;
    city: string;
    editable: boolean;
    street1: string;
    id: number;
    state: string;
    deleteIndicator: boolean;
    type: string;
  }

  export interface IPNSScheduledAppointment {
    date: string;
    updatedBy: string;
    locationName: string;
    patientId: number;
    scheduledVisitId: number;
    manualScheduledPhoneNumber: string;
    type: string;
    organizationId: number;
    visitId: number;
    manualScheduledAddress: IManualScheduledAddress;
    comment: string;
    id: number;
    providerName: string;
    status: string;
  }

  export interface ITask {
    taskType: string;
    updatedBy: string;
    visitId: number;
    id: number;
    taskStatus: string;
  }

  export interface IPNSVisit {
    bed: string;
    sourceId: number;
    reason: string;
    assignedUserFullName: string;
    patientId: number;
    endDate?: string;
    visitNumber: string;
    source: string;
    admitSource: string;
    customerId: number;
    unitId: number;
    id: number;
    recentVisit: boolean;
    assignedUser: string;
    tasks: ITask[];
    expectedStartDate?: Date;
    updatedBy: string;
    facilityId: number;
    dischargeDisposition: string;
    room: string;
    unit: string;
    service: string;
    visitClass: string;
    applicationId: number;
    facility: string;
    startDate: string;
    status: string;
  }

  export interface IPNSVisitSummary {
    lastName: string;
    patientId: number;
    nutritionUnableToServe: string;
    scheduleTransportationTaskStatus: string;
    a2hUnableToServe: string;
    prescriptionUnableToServe: string;
    organizationId: number;
    compositeDischargeTime: Date;
    a2hTaskStatus: string;
    visitId: number;
    readmissionRisk?: string;
    programPermission: string;
    customerId: number;
    taskManagements: IPNSTaskManagement[];
    scheduleFollowUpTaskStatus: string;
    prescriptionTaskStatus: string;
    id: number;
    updatedAt: number;
    scheduleTransportationUnableToServe: string;
    taskManagementSortMap: IPNSTaskManagementSortMap;
    organizationName: string;
    fullName1: string;
    noteCount: number;
    mrn: string;
    fullName2: string;
    pcpName: string;
    firstName: string;
    visitAttributes: IPNSVisitAttribute[];
    pnsDischargeDisposition: string;
    noteTopics: string[];
    hasDischargeOrder: boolean;
    name: string;
    totalRxDelivered: number;
    socialDeterminateTaskStatus?: string;
    scheduledAppointment: IPNSScheduledAppointment;
    visit: IPNSVisit;
    nutritionTaskStatus: string;
  }
