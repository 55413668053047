export interface IPNSJobs {
  id: string;
  updatedBy: string;
  jobType: string;
  triggerType?: string;
}

export enum IPNSJobTriggerType {
  EVERY_TWO_WEEKS,
  BI_MONTHLY,
  CRON,
}

export interface IPNSJobExecution {
  id?: string;
  oneTime?: boolean;
  updatedBy?: string;
  job?: IPNSJobType;
  jobType?: string;
  organization?: string;
  location?: string;
  notificationEmail?: string;
}

export interface IPNSJobType {
  name: string;
  id: string;
  oneTime?: boolean;
  type: IPNSJobTypeType;
  triggerType?: IPNSJobTriggerType;
  cronExpression?: string;
  updatedBy?: string;
  startDateAndTime?: Date;
  locationId?: string;
  locationName?: string;
  notificationEmail?: string;
  excludeHeader?: boolean;
  updatedAfter?: Date;
  outboundConnectorId?: string;
  inboundConnectorId?: string;
  inboundColumnNames?: string;
  inboundFilePattern?: string;
  nextRunTime?: Date;
  lastRunTime?: Date;
}

export enum IPNSJobTypeType {
  IMPORT,
  EXPORT,
  SYSTEM,
}
