export interface IPNSAssetRef {
  id: string;
  deleteIndicator: boolean;
  versionNumber: number;
  createdBy: string;
  updatedBy: string;
  createdDate: string;
  updatedDate: string;
  fileName: string;
  mimeType: string;
  binaryAssetId: string;
  deleted: boolean;
  new: boolean;
}

export interface IPNSFormTemplate {
  id: string;
  del_ind: boolean;
  displayName: string;
  topic: string;
  assetRef: IPNSAssetRef;
  facilityIds: string[];
  organizationIds: string[];
  deliveryTargets: [IPNSFormDeliveryTarget];
}

export interface IPNSFormDeliveryTarget {
  id: string;
  address: string;
  label: string;
}
