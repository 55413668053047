import {IPNSLocation} from '@portal-ng/PNSModels/PNSLocation.model';
import {IPNSVisit} from '@portal-ng/PNSModels/PNSVisit.model';
import {IPNSRole} from '@portal-ng/PNSModels/PNSRole.model';
import {IPNSUser} from './PNSUser.model';
import {IPNSCode} from '@portal-ng/PNSModels/PNSCode.model';

export interface IPNSSearchResults {
  type?: string;
  totalRecords: number;
  results: any;
}

export interface IPNSUserSearchResult {
  totalRecords: number;
  results: IPNSUser[];
}

export interface IPNSRoleSearchResults {
  totalRecords: number;
  results: IPNSRole[];
}

export interface IPNSLocationSearchResults {
  results: IPNSLocation[];
  totalRecords: number;
}

export interface IPNSVisitSearchResults {
  results: IPNSVisit[];
  totalRecords: number;
}

export interface IPNSCodeSearchResults {
  results: IPNSCode[];
  totalRecords: number;
}

// type SearchResultTypes = IPNSOrganization[] | IPNSConnection[] | IPNSJobs[] | IPNSUser[] | IPNSLocation[];
