export interface IPNSApplication {
id: string;
  updatedBy: string;
  organizationId: number;
  name: string;
  externalId: string;
  type: string;
  userName: string;
  vendor: string;
}
