import {IPNSJobType} from '@portal-ng/PNSModels/PNSJobs.model';

export interface IPNSExecution {
  id: string;
  jobType: IPNSJobType;
  startTime: Date;
  endTime: Date;
  status: string;
  exitStatus: string;
  processed: number;
  total: number;
  error: number;
}
