import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'portalNG-PNSMatChipField',
  templateUrl: './PNSMatChipField.component.html',
  styleUrls: ['./PNSMatChipField.component.scss'],
})
export class PNSMatChipFieldComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
