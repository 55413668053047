export enum PNSAddressType {
  Home,
  Shipping,
  Billing,
  Work,
}

export interface IPNSAddressGeolocation {
  lon: number;
  lat: number;
}

export interface IPNSAddress {
  id?: number;
  updatedBy?: string;
  street1: string;
  street2?: string;
  city: string;
  state: string;
  zip: string;
  longitude: string;
  latitude: string;
  geoLocation?: IPNSAddressGeolocation;
  type?: PNSAddressType;
  editable?: boolean;
  deleteIndicator?: boolean;
}
