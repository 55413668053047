import {IPNSLocation} from '@portal-ng/PNSModels/PNSLocation.model';

export interface IPNSProvider {
  id: string;
  firstName: string;
  sourceId: string;
  affiliatedOrgIds: string[];
  lastName: string;
  middleName: string;
  dob: Date;
  gender: string;
  updatedBy: string;
  npi: string;
  externalId: string;
  deaNumber: string;
  upin: string;
  applicationId: string;
  locationIds: string[];
  specialties: any;
  name: string;
  locations: IPNSLocation[];
}

export interface IPNSESProvider {
  sourceId: number;
  lastName: string;
  updatedBy: string;
  organizationName: string;
  fullName1: string;
  externalId: string;
  source: string;
  fullName2: string;
  createIfNotFound: boolean;
  deleteIndicator: false;
  organizationId: number;
  firstName: string;
  name: string;
  id: number;
  applicationId: number;
}
