export const environment = {
  production: false,
  type      : 'DEV',
  app       : 'Portal',
  name      : 'Patient Navigation System - DEV ENVIRONMENT',
  version   : '14',
  Portal    : {
    defaultLoggedInRoute: '/home',
  },
  PNS       : {
    searchLimit         : 1200,
    slowQuerySearchLimit: 100,
    endpoint            : 'https://dev-api.pns.patientengagementadvisors.com/v4/',
  },
  Auth : {
    baseURL: 'https://dev-login.pns.patientengagementadvisors.com',
    pubKeyURL: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_VBm3vWZyC/.well-known/jwks.json',
    loginURL: '/login',
    authURL: '/oauth2/token/',
    logoutURL: '/logout',
    region: 'us-east-1',
    userPoolId: 'us-east-1_VBm3vWZyC',
    clientId : '54vd5at030bofi98e4uu387a0h',
    responseType: 'code',
    portalAccessRole: 'Access Portal',
    refreshTokenInterval: (60 * 1000),
  },
  GoogleMaps: {
    APIKey: '.',
  },
  Microsoft : {
    ClientSecret: '.',
    TenantID    : '.',
    ClientID    : '.',
    Scope2      : '.',
    Scope       : 'https://graph.microsoft.com/',
    GrantType   : 'client_credentials',
  },
  Atlassian : {
    JIRA: {
      URL     : '/JIRA/',
      APIToken: '.',
      Username: '.',
    },
  },
  NewRelic  : {
    applicationId: '..',
    licenseKey   : '.',
    APIKey       : '.',
  },
  AWS       : {
    accessKeyId      : '..',
    secretAccessKey  : '..',
    releaseNoteBucket: 'releasenotes.pns.patientengagementadvisors.com',
    region           : 'us-east-1',
  }
};
