import {IPNSAddress} from '@portal-ng/PNSModels/PNSAddress.model';

export interface IPNSPatient {
  'id': number;
  'updatedBy': string;
  'organizationId': number;
  'organizationName': string;
  'applicationId': number;
  'source': string;
  'firstName': string;
  'lastName': string;
  'dob': string;
  'gender': string;
  'medicalRecordNumber': string;
  'socialSecurityNumber': string;
  'customerId': number;
  'deceased': boolean;
  'hasPowerOfAttorney': boolean;
  'insurancePlans': IPNSInsurancePlan[];
  'addresses': [IPNSAddress];
  'phones': IPNSPhone[];
  'relations': IPNSRelation[];
  'frequentFlyer': boolean;
  'sourceId': number;
}

export interface IPNSInsurancePlan {
  'id': number;
  'updatedBy': string;
  'applicationId': number;
  'source': string;
  'insuranceType': string;
  'patientId': number;
  'subscriberRelationship': string;
  'companyName': string;
  'companyPhone': string;
  'groupName': string;
  'memberId': string;
  'startDate': string;
  'planType': string;
  'rank': number;
  'sourceId': number;
}

export interface IPNSPhone {
  'id': number;
  'updatedBy': string;
  'type': string;
  'areaCode': string;
  'number': string;
  'freeText': string;
  'editable': boolean;
  'customerId': number;
  'preferredVoice': boolean;
  'preferredText': boolean;
  'formattedNumber': string;
  'deleteIndicator': boolean;
}

export interface IPNSRelation {
  'id': number;
  'updatedBy': string;
  'applicationId': number;
  'customerId': number;
  'type': string;
  'relationFirstName': string;
  'relationLastName': string;
  'relationDob': string;
  'relationGender': string;
  'relationship': string;
  'otherRelationship': string;
  'rank': number;
  'sourceId': number;
  'deleteIndicator': boolean;
}
