import {Location} from '@angular/common';
import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';
import {EditPNSLocation} from '@portal-ng/PNSLocation/actions/PNSLocation.action';
import {PNSLocationEffects} from '@portal-ng/PNSLocation/effects/PNSLocation.effects';
import {IPNSLocation, IPNSWebForm} from '@portal-ng/PNSModels';
import {PNSWebFormService} from '@portal-ng/PNSServices/PNSWebForm.service';

@Component({
  selector   : 'portalNG-PNSForm',
  templateUrl: './PNSWebForm.component.html',
  styleUrls  : ['./PNSWebForm.component.scss'],
})

export class PNSWebFormComponent implements OnInit {
  @Input() formData: IPNSWebForm;
  @Input() formEntity: any;
  form: FormGroup;

  constructor(
    private locEffects: PNSLocationEffects,
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private store: Store<IAuthState>,
    private cdf: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.form = PNSWebFormService.createForm(this.formEntity, this.formData);
    this.cdf.detectChanges();
  }

  saveForm(form: FormGroup) {
    const editedLocation: IPNSLocation = PNSWebFormService.updateObject(this.formEntity, this.formData, form);
    this.store.dispatch(new EditPNSLocation(editedLocation));
    this.router.navigate(['/admin/locations/facility/list']);
  }
}
