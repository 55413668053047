import {IPNSSearch} from '@portal-ng/PNSModels/PNSSearch.model';

export interface IPNSVisitSummarySearch extends IPNSSearch {
  locationId?: string | null;
  sortOrder: string;
  searchTerm: string;
  unitID?: string;
  unitIds?: string[];
  sortField?: string;
}
