export interface INewRelicApplicationSummary {
  response_time: number;
  throughput: number;
  error_rate: number;
  apdex_target: number;
  apdex_score: number;
  host_count: number;
  instance_count: number;
}

export interface INewRelicApplicationSettings {
  app_apdex_threshold: number;
  end_user_apdex_threshold: number;
  enable_real_user_monitoring: boolean;
  use_server_side_config: boolean;
}

export interface INewRelicApplicationLinks {
  application_instances: number[];
  servers: number[];
  application_hosts: number[];
}

export interface INewRelicApplication {
  id: number;
  name: string;
  language: string;
  health_status: string;
  reporting: boolean;
  last_reported_at: string;
  application_summary: INewRelicApplicationSummary;
  settings: INewRelicApplicationSettings;
  links: INewRelicApplicationLinks;
}

export interface INewRelicApplicationSummaryLinks {
  'application.servers': string;
  'application.server': string;
  'application.application_hosts': string;
  'application.application_host': string;
  'application.application_instances': string;
  'application.application_instance': string;
}

export interface INewRelicSummary {
  application?: INewRelicApplication;
  applications?: INewRelicApplication[];
  links: INewRelicApplicationSummaryLinks;
}
