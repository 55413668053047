export interface IJIRAIssue {
  fields: {
    project: IJIRAEntityReference;
    assignee: IJIRAEntityNameReference;
    summary: string;
    description: any;
    components: IJIRAEntityNameReference[];
    issuetype: IJIRAEntityNameReference;
    customfield_10030?: string; // Location Name
    customfield_10031?: string; // Org Name
    customfield_10032?: string; // MRN1
    customfield_10033?: string; // MRN2
    customfield_10034?: string; // Patient Bed
    customfield_10035?: string; // Location ID
    customfield_10036?: IJIRAValueReference; // Environment (DEV/PROD)
  };
}

export interface IJIRAEntityReference {
  id: string;
}

export interface IJIRAValueReference {
  value: string;
}

export interface IJIRAEntityNameReference {
  name: string;
}

export interface IJIRAIssueResponse {
  id: number;
  key: number;
  self: boolean;
  status: IJIRAStatus;
}

export interface IJIRAStatus {
  status: number;
  errorCollection: {
    errorMessages: string[];
    errors: any;
  };
}
