import {IPNSAddress} from './PNSAddress.model';
import {IPNSLocationConfigurationModel} from './PNSLocationConfiguration.model';
import {IPNSFormTemplate} from '@portal-ng/PNSModels/PNSForm.model';

export interface IPNSLocationFormData {
  facilityId: string;
  facilityName: string;
  forms: IPNSFormTemplate[];
}

export interface IPNSLocation {
  id: string;
  updatedBy: string;
  organizationId: string;
  organizationName: string;
  externalId: string;
  name: string;
  abbreviation: string;
  operational: boolean;
  eSchedulingEnabled: false;
  type: string;
  address: IPNSAddress;
  configuration: IPNSLocationConfigurationModel;
  locationId: string;
  parentId: string;
  timeZone: string;
  locationName: string;
  eschedulingEnabled: boolean;
  deleteIndicator: false;
}
