export type questionCategory =
  'ADL'
  | 'COPD'
  | 'Demographic'
  | 'Home Health'
  | 'Nutrition'
  | 'PCP'
  | 'Prescriptions'
  | 'Specialist'
  | 'Transportation';
export type questionType = 'SocialDeterminate';
export type questionAnswerType = 'ConfirmHomeAddress'
  | 'ConfirmMobilePhoneNumber'
  | 'ContactNameAndNumber'
  | 'Freetext'
  | 'PickOne'
  | 'PickOneWithOther'
  | 'PrimaryCarePhysician'
  | 'Specialist';

export interface IPNSQuestionAnswers {
  answerText: string;
}

export interface IPNSQuestions {
  name: string;
  version: number;
  updatedDate: Date;
  questions: IPNSQuestion[];
}

export interface IPNSQuestion {
  'category': questionCategory;
  'orderNumber': number;
  'answerType': questionAnswerType;
  'questionText': string;
  'questionType': questionType;
  'answers'?: IPNSQuestionAnswers[];
  'locationId': string;
}
