import {IPNSAddress} from './PNSAddress.model';

export interface IPNSGenerosityProvider {
  id: string;
  updatedBy: string;
  name: string;
  description: string;
  phoneNumber: string;
  emailAddress: string;
  url: string;
  address: IPNSAddress;
  timeZone: string;
  services: IPNSGenerosityProviderService[];
}

export interface IPNSGenerosityProviderService {
  id: string;
  name: string;
  description: string;
  category: string; // TODO IPNSProviderType
  cost: string;
  phoneNumber: string;
  emailAddress: string;
  notes: IPNSNote[];
  attributes: IPNSAttribute[];
}

export interface IPNSAttribute {
  key: string;
  value: string;
}

export interface IPNSNote {
  note: string;
  created: Date;
  author: string;
}
