import {HttpHeaders} from '@angular/common/http';

export interface IPNSError {
  errorCode: string;
  errorMessage: string;
  errorDescription: string;
}

export interface IPNSHttpError {
  error: IPNSError;
  headers: HttpHeaders;
  status: number;
  statusText: string;
  url: string;
}
