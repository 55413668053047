
export interface IPNSSearch {
  limit?: number | null;
  offset?: number | null;
}

export interface IPNSFormTemplateSearch extends IPNSSearch {
  facilityId?: string | null;
  topic?: string | null;
  topics?: string | null;
}
